/** @jsxImportSource theme-ui */
import React from "react";
import { graphql } from "gatsby";
import Layout from "../../../components/Layout";
import Pagination from "../../../components/Pagination";
import Breadcrumbs from "../../../components/Breadcrumbs";
import Seo from "../../../components/Seo";
import VehicleList from "./VehicleList";

const PaginationTemplate = ({ data, pageContext, location }) => {
  const { edges } = data.allMarkdownRemark;
  const vehicles = edges.map((edge) => edge.node);

  return (
    <>
      <Seo
        title="Fahrzeuge | Über uns"
        pathname={location.pathname}
        description="Liste von Fahrzeugen der Feuerwehr Arborn, welche aktuell im Dienst sind oder frühere Einsätze begleitet haben."
      />

      <Layout>
        <Breadcrumbs names={["Über uns", "Fahrzeuge"]} location={location} />

        <VehicleList vehicles={vehicles} />
        <Pagination path="/ueber-uns/fahrzeuge" {...pageContext} />
      </Layout>
    </>
  );
};

export default PaginationTemplate;

export const vehiclePaginationQuery = graphql`
  query VehiclePagination($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      filter: { frontmatter: { templateName: { eq: "about/vehicles" } } }
      sort: { fields: frontmatter___active___to, order: DESC }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            dispatcherName
            image {
              childImageSharp {
                gatsbyImageData(height: 290)
              }
            }
            active {
              from
              to
            }
          }
        }
      }
    }
  }
`;
